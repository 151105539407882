.ordersMain{
    margin-top: 80px;
    align-items: center;
    display: flex;
}

.ordersList{
    border-radius: 12px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}

.orderRecordSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1E9CED;
    padding: 14px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.orderRecordText{
    font-size: 20px !important;
    font-weight: 600 !important;
    color: white;
}

.viewContainer {
  max-height: 90vh;
  overflow-y: auto;
}
  
  /* For WebKit browsers (Chrome, Safari) */
.viewContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
  
/* For Firefox */
.viewContainer {
  scrollbar-width: none; /* hides scrollbar */
}

/* For Internet Explorer and Edge */
.viewContainer {
  -ms-overflow-style: none; /* hides scrollbar */
}

.aTag {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.viewText {
  font-size: 13px;
  margin-right: 5px;
  color: green;
  text-decoration: underline;
}

.trackText {
  font-size: 13px;
  margin-left: 5px;
  color: red;
  text-decoration: underline;
}

.noRecordsFound {
  font-size: 20px !important;
  margin-top: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
  color: red
}


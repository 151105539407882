.riderMain {
    font-size: 30px;
    margin-top: 50px;
    align-items: center;
    padding-bottom: 1%;
}

.addRiderform {
    background-color: white;
    margin-top: 6.5%;
    margin-left: 2%;
    border-radius: 12px;
    margin-right: 1%;
    margin-bottom: 2%;
    width: 60%;
    // padding: 2%; 
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
}

.addRiderTextSection {
    margin: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    background-color:#1E9CED;
    padding-top: 10px;
    padding-left: 20px;
}

.addRiderText {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 12px !important;
    color: white;
    padding-top: 8px;
    margin-bottom: 14px !important;
}

.addRiderformText{
    margin-bottom: 2px !important;
}

.updateRiderformText {
    margin-bottom: 20px !important;
}

.riderListTable{
    margin-left: 2%;
    margin-right: -22px !important;
    margin-top: 3%;
    align-self: self-start;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}

.rider-list-header {
    background-color:#1E9CED;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.riderRecordsText {
    margin-left: 2% !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.update-rider-modal-header {
    justify-content: space-between;
    background-color:#1E9CED;
    margin: -32px;
    padding-top: 14px;
    padding-left: 14px;
    margin-bottom: 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.update-rider-modal-header-close-icon {
    margin-right: 10px; 
    margin-top: 2px;
    cursor: pointer; 
    color: white; 
    font-size: 30px !important;
}

.view-rider-modal-header {
    justify-content: space-between;
    background-color: #1E9CED;
    margin: -32px;
    margin-bottom: 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.view-rider-modal-header-text {
    // padding: 12px;
    // font-size: 22px;
    // font-weight: 600;
    color: white;
}

.view-rider-modal-header-close-icon{
    // margin-right: '10px';
    // margin-top: '15px';
    cursor: 'pointer';
    // color: 'white';
    // font-size: '28px';
}

.loader {
    color: white !important;
}

.tableLoader {
    align-self: center;
    margin-left: 350% !important;
    margin-top: 25% !important;
}
.settingsMain {
    font-size: 30px;
    margin-top: 80px;
    align-items: center;
    display: flex;
    // justify-content: space-around;
}

.settingsSection {
    width: 34%;
    box-shadow: 0 0 10px rgba( 0, 0, 0, 0.3 ) !important;
    border-radius: 12px !important;
}

.orgSettingsSection {
    background-color: #1E9CED;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 12px;
}

.settingsTextField {
    margin-top: 25px !important;
}

.settingsButtonSection{
    margin-top: 22px;
    padding-bottom: 20px;
}
.sideBar{
    .active-item{
        // background-color: $green-base !important;
        // color: $white;
        color: #0480cd;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom: 3px solid #1E9CED;
        background-color: rgb(229, 227, 227);

    }
    .inactive-item-text{
        font-size: 70px !important;
        // color: $green-base;
        color: black;
        font-weight: 600 !important;
    }
    .active-item-icon{
        color: 'white';
    }
    .inactive-item-icon{
        // color: $green-base;
        color: black;
    }
}
.clientMain {
    font-size: 30px;
    margin-top: 80px;
    align-items: center;
    display: flex;
    // justify-content: space-around;
}

.clientListTable {
    margin: 1%;
    box-shadow: 0 0 10px rgba(214, 188, 188, 0.2) !important;
}

.clientText {
    font-size: 25px !important;
    font-weight: 600 !important;
    padding-top: 10px !important;
}

.loader {
    color: white;
}

.tableLoader {
    align-self: center;
    margin-left: 340%;
    margin-top: 20%;
}

.updatePassowrdForm {
    margin-left: 10px;
    margin-top: -23.5%;
    border-radius: 12px !important;
}

.resetPasswordModal {
    // position: absolute;
    // top: 50%;
    // left: 55%;
    // transform: translate(-50%, -50%);
    width: 400px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    padding: 6%;
    display: flex;
    flex-direction: column;
    outline: none
}

.restPasswordTextSection {
    margin-Top: 12px !important;
}

.restPasswordText {
    // margin-Top: 20px !important;
    width: 100%;
}

.updateProfileModal {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 24%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    outline: none;
}



.updateProfileText {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 15px !important;
}


.profileSection {
    // padding: 2%;
    border-radius: 12px !important;
    width: 32%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}

.profileText {
    // font-size: 22px !important;
    // font-weight: 600 !important;
    // margin-top: 10x !important;
    // margin-bottom: -10px !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: white;
    // text-decoration: underline;
}

.imgAndProfileSection {
    display: flex !important;
    align-items: center;
    // margin-top: 10px;
}

.profileName {
    font-weight: 600 !important;
    color: rgb(107, 107, 107);
    margin-left: 20px !important;
}

.profileTextSection {
    display: flex;
    flex-direction: column;
}

.profileTextField{
    margin-top: 20px !important;
}

.profileBothButtons{
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
}

.resetPasswordSection {
    width: 32%;
    // padding: 2%;
    margin-left: 3%;
    height: min-content;
    border-radius: 12px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}

.resetPassTextSection {
    background-color: #1E9CED;
    // margin: -25px;
    margin-bottom: 10px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 8px;
}

.resetPassText {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: white;
}
// .dashBoard{
//     justify-content: center !important;
//     justify-items: center !important;
//     align-items: center !important;
//     align-content: center;
//     justify-self: center !important;
//     position: relative;
//     top: 70px;
// }

.metricCards {
    box-shadow: 0 0 10px rgba( 0, 0, 0, 0.5 ) !important;
}